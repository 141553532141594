import { styled, withStyle } from 'styletron-react';
import { NavLink } from 'react-router-dom';

import { getTheme } from '../../../ui/themes';
import { GoogleColored, Github } from '../../icons';

const ButtonStyles = {
    common: {
        boxSizing: 'border-box',
        minWidth: '0',
        minHeight: '0',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        padding: '8px 16px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: getTheme().primary,
        borderRadius: '2px',
        backgroundColor: getTheme().primary,
        boxShadow: 'none',

        fontSize: '14px',
        lineHeight: '1',
        color: getTheme().primaryText,
        fill: getTheme().primaryText,
        textDecoration: 'none',

        cursor: 'pointer',
        transition: 'all .2s ease-in-out',

        ':hover': {
            color: getTheme().primaryText,
            fill: getTheme().primaryText,
            backgroundColor: getTheme().primaryDark,
            borderColor: getTheme().primaryDark,
        },
    },

    small: {
        fontSize: '12px',
        padding: '6px 12px',
    },

    large: {
        fontSize: '16px',
        padding: '12px 18px',
    },

    inverted: {
        color: getTheme().primary,
        fill: getTheme().primary,
        backgroundColor: getTheme().primaryText,
        borderColor: getTheme().primary,

        ':hover': {
            color: getTheme().primaryText,
            fill: getTheme().primaryText,
            backgroundColor: getTheme().primary,
            borderColor: getTheme().primary,
        },
    },

    muted: {
        color: getTheme().primaryTextMuted,
        fill: getTheme().primaryTextMuted,
        backgroundColor: getTheme().defaultTextMuted,
        borderColor: getTheme().defaultTextMuted,

        ':hover': {
            color: getTheme().primaryText,
            fill: getTheme().primaryText,
            backgroundColor: getTheme().defaultTextLight,
            borderColor: getTheme().defaultTextLight,
        },
    },

    mutedInverted: {
        color: getTheme().defaultTextMuted,
        fill: getTheme().defaultTextMuted,
        backgroundColor: getTheme().primaryText,
        borderColor: getTheme().defaultTextMuted,

        ':hover': {
            color: getTheme().primaryText,
            fill: getTheme().primaryText,
            backgroundColor: getTheme().defaultTextMuted,
            borderColor: getTheme().defaultTextMuted,
        },
    },

    danger: {
        color: getTheme().primaryText,
        fill: getTheme().primaryText,
        backgroundColor: getTheme().danger,
        borderColor: getTheme().danger,

        ':hover': {
            color: getTheme().primaryText,
            fill: getTheme().primaryText,
            backgroundColor: getTheme().dangerDark,
            borderColor: getTheme().dangerDark,
        },
    },

    dangerInverted: {
        color: getTheme().danger,
        fill: getTheme().danger,
        backgroundColor: getTheme().primaryText,
        borderColor: getTheme().danger,

        ':hover': {
            color: getTheme().primaryText,
            fill: getTheme().primaryText,
            backgroundColor: getTheme().danger,
            borderColor: getTheme().danger,
        },
    },

    disabled: {
        opacity: '0.3',
        cursor: 'not-allowed',
        color: getTheme().primaryText,
        fill: getTheme().primaryText,
        backgroundColor: getTheme().defaultTextMuted,
        borderColor: getTheme().defaultTextMuted,

        ':hover': {
            color: getTheme().primaryText,
            fill: getTheme().primaryText,
            backgroundColor: getTheme().defaultTextMuted,
            borderColor: getTheme().defaultTextMuted,
        },
    },

    link: {
        backgroundColor: 'transparent',
        color: getTheme().primary,
        fill: getTheme().primary,
        borderColor: 'transparent',

        ':hover': {
            color: getTheme().primaryDark,
            fill: getTheme().primaryDark,
        },
    },

    login: {
        borderWidth: '0',
        padding: '12px 18px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        borderRadius: '4px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1)',
        backgroundColor: getTheme().primaryText,
        color: getTheme().defaultTextLight,
        fill: getTheme().defaultTextLight,
        transition: 'all .3s ease-in-out',

        ':hover': {
            backgroundColor: '#f8f8f8',
            boxShadow: '0 3px 7px rgba(0,0,0,0.2), 0 2px 3px rgba(0,0,0,0.2)',
        },
    },
    loginIcon: {
        width: '24px',
        height: '24px',
        verticalAlign: 'bottom',
        marginRight: '16px',
    },
};



export const LoginButton = styled('button', {
    ...ButtonStyles.common,
    ...ButtonStyles.login,
});

export const LoginLink = styled('a', {
    ...ButtonStyles.common,
    ...ButtonStyles.login,
});

export const Button = styled('button', props => ({
    ...ButtonStyles.common,
    ...(props.$small && ButtonStyles.small),
    ...(props.$large && ButtonStyles.large),
    ...(props.$notRounded && { borderRadius: '0' }),

    ...(props.$inverted && ButtonStyles.inverted),
    ...(props.$danger && {
        ...ButtonStyles.danger,
        ...(props.$inverted && ButtonStyles.dangerInverted),
    }),
    ...(props.$muted && {
        ...ButtonStyles.muted,
        ...(props.$inverted && ButtonStyles.mutedInverted),
    }),
    ...(props.disabled && ButtonStyles.disabled),
}));

export const NavButton = styled(NavLink, props => ({
    ...ButtonStyles.common,
    ...(props.disabled && { opacity: '0.5', cursor: 'not-allowed' }),
    ...(props.$small && ButtonStyles.small),
    ...(props.$large && ButtonStyles.large),
    ...(props.$notRounded && { borderRadius: '0' }),

    ...(props.$inverted && ButtonStyles.inverted),
    ...(props.$danger && ButtonStyles.danger),
    ...(props.$muted && {
        ...ButtonStyles.muted,
        ...(props.$inverted && ButtonStyles.mutedInverted),
    }),
}));

/** @deprecated */
export const InvertedButton = withStyle(Button, ({
    ...ButtonStyles.inverted,
}));

export const LinkButton = styled('button', ({ $small = false }) => ({
    ...ButtonStyles.common,
    ...ButtonStyles.link,
    ...($small && ButtonStyles.small),
}));

export const LinkButtonA = styled('a', ({ $small }) => ({
    ...ButtonStyles.common,
    ...ButtonStyles.link,
    ...($small && ButtonStyles.small),
}));


export const GoogleIcon = styled(GoogleColored, ButtonStyles.loginIcon);
export const GitHubIcon = styled(Github, ButtonStyles.loginIcon);
