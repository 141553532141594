import React, { useEffect, useState, useRef } from 'react';
import ClipboardJS from 'clipboard';

import { hash } from '../../../helpers/functions';
import { CopyButton } from './ClipboardButtonStyles';


const CopyToClipboardButton = ({
    textToCopy,
    $right = false,
    $lSpaced = false,
}) => {
    const [copied, setCopied] = useState(false);
    const [btnId] = useState(() => {
        return `copyToClipboardBtn_${hash(4)}`;
    });

    const clipboard = useRef(undefined);
    useEffect(() => {
        setCopied(false);
        if (document) {
            clipboard.current = new ClipboardJS(document.getElementById(btnId));
            clipboard.current.on('success', () => {
                setCopied(true);
            });
            return () => clipboard.current.destroy();
        }
        return () => {};
    }, [textToCopy]);

    return (
        <CopyButton
            id={btnId}
            data-clipboard-text={textToCopy}
            $right={$right}
            $lSpaced={$lSpaced}
        >
            { copied ? 'Copied' : 'Copy' }
        </CopyButton>
    );
};

export default CopyToClipboardButton;
