import defaultTheme from './default';


let layout = 'default';

export const setTheme = (theme) => {
    layout = theme || 'default';
};

export const getTheme = () => {
    switch (layout) {
        case 'light':
        case 'dark':
        default:
            return defaultTheme;
    }
};

export const getLum = (color) => {
    const r = parseInt(color.substring(0, 2), 16) / 255;
    const g = parseInt(color.substring(2, 4), 16) / 255;
    const b = parseInt(color.substring(4, 6), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    return (max + min) / 2;
};
