import React from 'react';

const Earth = ({ className = '', size = '1em' }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 612 612">
        <g>
            <path d="M587.572,186.881c-32.266-75.225-87.096-129.934-162.949-162.285C386.711,8.427,347.328,0.168,305.832,0.168
                c-41.488,0-80.914,8.181-118.784,24.428C111.824,56.861,56.415,111.535,24.092,186.881C7.895,224.629,0,264.176,0,305.664
                c0,41.496,7.895,81.371,24.092,119.127c32.323,75.346,87.731,130.348,162.956,162.621c37.87,16.247,77.295,24.42,118.784,24.42
                c41.496,0,80.879-8.259,118.791-24.42c75.854-32.352,130.684-87.403,162.949-162.621C603.819,386.914,612,347.16,612,305.664
                C612,264.176,603.819,224.757,587.572,186.881z M204.109,57.054c-26.434,33.458-45.836,75.953-58.221,127.485H65.917
                C95.362,127.318,144.889,81.475,204.109,57.054z M37.141,305.664c0-29.11,4.683-57.214,13.72-84.319h87.667
                c-4.348,28.439-6.36,56.551-6.36,84.319c0,27.44,2.013,55.217,6.36,83.656H50.19C41.489,362.215,37.141,334.111,37.141,305.664z
                 M65.917,426.126h79.972c12.385,52.531,31.788,95.697,58.221,128.827C143.882,529.854,94.356,484.012,65.917,426.126z
                 M287.094,571.679c-21.751-6.354-42.16-22.415-60.562-48.513c-18.403-26.105-32.458-58.557-42.16-97.04h102.722V571.679z
                 M287.094,389.32H176.005c-4.354-29.782-6.696-57.558-6.696-83.656c0-28.104,2.341-56.215,7.367-84.319h110.417V389.32
                L287.094,389.32z M287.094,184.54H184.372c10.372-38.148,24.506-70.214,42.831-96.034c18.481-26.048,38.141-42.16,59.892-48.185
                V184.54z M546.084,184.54h-79.973c-12.378-51.533-31.787-94.028-58.221-127.485C467.118,81.475,516.638,127.318,546.084,184.54z
                 M324.906,40.321c21.751,6.025,41.41,22.137,59.892,48.185c18.331,25.82,32.458,57.886,42.83,96.034H324.906V40.321z
                 M324.906,221.345h110.417c5.025,28.104,7.367,56.215,7.367,84.319c0,26.099-2.342,53.874-6.696,83.656H324.906V221.345z
                 M324.906,571.679V426.126h102.722c-10.036,38.483-24.277,70.806-42.495,97.04C366.873,549.464,346.992,565.661,324.906,571.679z
                 M407.891,554.953c26.434-33.13,45.843-76.296,58.221-128.827h79.973C517.645,484.012,468.117,529.854,407.891,554.953z
                 M561.81,389.32h-88.338c4.354-28.44,6.36-56.216,6.36-83.656c0-27.768-2.006-55.879-6.36-84.319h87.667
                c9.037,27.104,13.72,55.208,13.72,84.319C574.858,334.111,570.512,362.215,561.81,389.32z"/>
        </g>
    </svg>
);

export default Earth;
