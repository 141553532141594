import React from 'react';

const More = ({ className = '', size = '1em' }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 60 60">
        <g>
            <path d="M8,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S12.411,22,8,22z"/>
            <path d="M52,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S56.411,22,52,22z"/>
            <path d="M30,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S34.411,22,30,22z"/>
        </g>
    </svg>
);

export default More;
