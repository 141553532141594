import { toArray } from './functions';

export const zIndex = {
    behind: '-1',
    normal: '1',
    over: '2',
    front: '7',

    resizeBar: '9',
    fixedHeader: '10',

    tooltip: '100',

    modal: '1001',
    modalShade: '1000',
};

let cachedCombinedStyles = {};

/**
 * Combines multiple style objects into a single one.
 * This function also takes care of multiple declarations of media queries,
 * and successfully merges them, instead of overwriting with the latter.
 *
 * Ex:
 *  styles1 = {
 *      width: '10px',
 *      '@media(...)': {
 *          width: '20px'
 *      }
 *  }
 *  styles2 = {
 *      background: 'red',
 *      '@media(...)': {
 *          background: 'blue'
 *      }
 *  }
 *
 *  styles3 = { ...styles1, ...styles2 }
 *  This will produce an incorrect output of
 *      { width: '10px', background: 'red', '@media(...)': { background: 'blue' } }
 *
 *  styles4 = combineStyles(styles1, styles2)
 *  This will correctly combine the media query styles and produce
 *      { width: '10px', background: 'red', '@media(...)': { width: '20px', background: 'blue' } }
 *
 * Can be used with indefinite number of parameters.
 *
 * @param args
 * @returns {*}
 */
export const combineStyles = (...args) => {
    const key = JSON.stringify({...args});

    if (!cachedCombinedStyles[key]) {
        // filter out empty values
        const styles = args.filter(item => item);

        if (!styles.length) {
            return false;
        } else if (styles.length === 1) {
            return styles[0];
        }

        const base = styles[0];
        const overwrites = styles[1];

        let combined = [
            // take the unique keys from both
            ...new Set([...Object.keys(base), ...Object.keys(overwrites)]),
        ]
            .map((prop) => {
                // overwrite simple values (string or numeric)
                if (typeof overwrites[prop] !== 'object' && overwrites[prop]) {
                    return { [prop]: overwrites[prop] };
                    // no overwrite, just base
                } else if (!overwrites[prop]) {
                    return { [prop]: base[prop] };
                }

                // combine object values from both styles
                return { [prop]: { ...base[prop], ...overwrites[prop] } };
            })
            // combine all array elements into a single object
            .reduce((group, item) => ({ ...group, ...item }), {});

        // continue for remaining styles
        if (args.length > 2) {
            combined = combineStyles(combined, args[2], ...toArray(args.slice(3)));
        }

        cachedCombinedStyles[key] = combined;
    }

    return cachedCombinedStyles[key];
};



// selectorSupported lovingly lifted from the mad italian genius, diego perini
// http://javascript.nwbox.com/CSSSupport/
const selectorSupported = (selector) => {
    let support, link, sheet, doc = document,
        root = doc.documentElement,
        head = root.getElementsByTagName('head')[0],

        impl = doc.implementation || {
                hasFeature: function() {
                    return false;
                }
            };

    link = doc.createElement("style");
    link.type = 'text/css';

    (head || root).insertBefore(link, (head || root).firstChild);

    sheet = link.sheet || link.styleSheet;

    if (!(sheet && selector)) return false;

    support = impl.hasFeature('CSS2', '') ?
        (selector) => {
            try {
                sheet.insertRule(selector + '{ }', 0);
                sheet.deleteRule(sheet.cssRules.length - 1);
            } catch (e) {
                return false;
            }
            return true;
        } : (selector) => {
            sheet.cssText = selector + ' { }';
            return sheet.cssText.length !== 0 && !(/unknown/i).test(sheet.cssText) && sheet.cssText.indexOf(selector) === 0;
        };

    return support(selector);
};

let placeholderSupport = ['::placeholder'];
if (typeof window !== 'undefined') {
    if (selectorSupported('::-webkit-placeholder')) placeholderSupport.push('::-webkit-placeholder');
    if (selectorSupported('::-moz-placeholder')) placeholderSupport.push('::-moz-placeholder');
    if (selectorSupported('::-ms-placeholder')) placeholderSupport.push('::-ms-placeholder');
    if (selectorSupported(':-ms-placeholder')) placeholderSupport.push(':-ms-placeholder');
}

export const placeholderPseudo = (styles, prefix = '') => ({
    ...placeholderSupport
        .map(ps => ({ [prefix + ps]: styles }))
        .reduce((acc,r) => ({ ...acc, ...r }), {})
});
