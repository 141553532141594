import React from 'react';

const SnipitLogo = ({ className = '', size = '1em' }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 128 128">
        <g transform="translate(0,16)">
            <path d="M79.7276023,1.34926195 L75.3942027,0.161284895 C74.8364359,-0.0247189293 74.2869904,0.0337743786 73.7527189,0.336275335 C73.2167342,0.639021033 72.854761,1.09350669 72.6687572,1.69899809 L46.6052161,91.9079159 C46.4192122,92.513652 46.4774608,93.0848795 46.7802065,93.6198853 C47.0829522,94.15587 47.5364589,94.5168642 48.1429293,94.7028681 L52.4751052,95.8913346 C53.0340956,96.0788069 53.5815832,96.0200688 54.1173231,95.7170784 C54.6533078,95.4128642 55.0145468,94.959847 55.2003059,94.3553346 L81.2645813,4.14494837 C81.4503403,3.53945698 81.3925813,2.96822945 81.0893461,2.432 C80.7863556,1.8960153 80.3333384,1.53502103 79.7276023,1.34926195 Z" />
            <path d="M40.6680229,18.9589293 C40.6680229,18.3534379 40.4347839,17.8172084 39.9690402,17.3517094 L36.4748604,13.8577744 C36.0091166,13.3920306 35.4731319,13.1585468 34.8676405,13.1585468 C34.2621491,13.1585468 33.7259197,13.3922753 33.2604207,13.8577744 L0.698982792,46.4192122 C0.23250478,46.884956 0,47.4209407 0,48.0264321 C0,48.6319235 0.233239006,49.1679082 0.698982792,49.6334073 L33.2601759,82.1948451 C33.725675,82.6613231 34.2609254,82.8933384 34.8673958,82.8933384 C35.4738662,82.8933384 36.0091166,82.6605889 36.4746157,82.1948451 L39.9687954,78.7028681 C40.4345392,78.237369 40.6677782,77.7011396 40.6677782,77.094914 C40.6677782,76.4901568 40.4345392,75.9541721 39.9687954,75.488673 L12.5080229,48.0264321 L39.9690402,20.5661491 C40.4355182,20.1006501 40.6680229,19.5644207 40.6680229,18.9589293 Z" />
            <path d="M127.171304,46.418478 L94.6091319,13.8570402 C94.1436329,13.3915411 93.6074034,13.1578126 93.002891,13.1578126 C92.3964207,13.1578126 91.8621491,13.3915411 91.3949369,13.8570402 L87.9022256,17.3507304 C87.4367266,17.8164742 87.2044665,18.3517247 87.2044665,18.9579503 C87.2044665,19.5641759 87.4364818,20.0996711 87.9022256,20.5651702 L115.363732,48.0261874 L87.9022256,75.488673 C87.4367266,75.9541721 87.2044665,76.4904015 87.2044665,77.094914 C87.2044665,77.7013843 87.4364818,78.237369 87.9022256,78.7028681 L91.3949369,82.1948451 C91.8621491,82.6613231 92.3966654,82.8933384 93.002891,82.8933384 C93.6076482,82.8933384 94.1436329,82.6605889 94.6091319,82.1948451 L127.171304,49.633652 C127.637048,49.168153 127.869797,48.6311893 127.869797,48.0256979 C127.869797,47.4199618 127.637048,46.8839771 127.171304,46.418478 Z" />
        </g>
    </svg>
);

export default SnipitLogo;
