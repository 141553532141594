import React from 'react';

import config from '../../../config/environments';
import {
    Footer,
    FooterLinkContainer,
    FooterLink,
    FooterWatermarkContainer,
} from './CodeEditorStyles';


export default ({ title, permalink = null }) => (
    <Footer>
        <FooterLinkContainer>
            { permalink
                ? (
                    <FooterLink
                        href={`${config.application.baseUrl}${permalink}`}
                        title={`Open ${title} on Snipit.io`}
                        target="_blank"
                        rel="noopener"
                    >
                        <strong>{title}</strong>
                    </FooterLink>
                )
                : <strong>{title}</strong>
            }
        </FooterLinkContainer>
        <FooterWatermarkContainer>
            hosted with <span style={{
            fontSize: '1.3em'
        }}>&hearts;</span> by <FooterLink
            href="https://snipit.io/"
            title="Save and organize your code snippets in the cloud"
            target="_blank"
            rel="noopener"
        >Snipit.io</FooterLink>
        </FooterWatermarkContainer>
    </Footer>
)
