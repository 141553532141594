import { withStyle } from 'styletron-react';

import { Button } from '../input/ButtonStyles';
import { zIndex } from '../../../helpers/styles';

export const CopyButton = withStyle(Button, ({ $right = false, $lSpaced = false }) => ({
    padding: '3px 5px',
    ...($lSpaced && { marginLeft: '8px' }),
    borderRadius: '2px',
    verticalAlign: 'bottom',
    fontSize: '8px',
    fontWeight: '800',
    textTransform: 'uppercase',
    ...($right && {
        position: 'absolute',
        zIndex: zIndex.front,
        right: '3px',
        top: '3px',
    }),
}));
