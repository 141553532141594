import React from 'react';

const Padlock = ({ className = '', size = '1em' }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 508.52 508.52">
        <g>
            <path d="M444.955,190.695h-31.783V161.36C413.172,72.242,342.043,0,254.26,0
                    C166.508,0,95.347,72.242,95.347,161.36v29.335H63.565c-17.544,0-31.782,14.239-31.782,31.782v254.26
                    c0,17.544,14.239,31.782,31.782,31.782h381.39c17.544,0,31.782-14.239,31.782-31.782v-254.26
                    C476.737,204.933,462.499,190.695,444.955,190.695z M127.13,161.36c0-71.447,57.018-129.577,127.13-129.577
                    s127.13,58.13,127.13,129.577v29.335H127.13V161.36z M444.955,476.737H63.565v-254.26h381.39V476.737z"/>
            <path d="M254.26,413.172c35.12,0,63.565-28.445,63.565-63.565s-28.445-63.565-63.565-63.565
                    s-63.565,28.445-63.565,63.565S219.14,413.172,254.26,413.172z M254.26,317.825c17.512,0,31.782,14.27,31.782,31.783
                    c0,17.512-14.27,31.782-31.782,31.782s-31.782-14.27-31.782-31.782C222.477,332.095,236.748,317.825,254.26,317.825z"/>
        </g>
    </svg>
);

export default Padlock;
