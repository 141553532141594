import { styled } from 'styletron-react';

import { combineStyles, placeholderPseudo } from '../../../helpers/styles';
import { getTheme } from '../../themes';

const MQ = { s: '@media (min-width: 568px)', m: '@media (min-width: 768px)' };
const colors = getTheme();

const Styles = {
    box: {
        boxSizing: 'border-box',
        margin: '0',
        flex: '0 0 100%',
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${colors.primaryTextMuted}`,
        borderRadius: '2px',

        [MQ.s]: {
            flex: '1 1 auto',
        },
    },

    input: {
        appearance: 'none',
        width: '0',
        paddingTop: '12px',
        paddingRight: '12px',
        paddingBottom: '12px',
        paddingLeft: '12px',
        background: colors.primaryText,
        boxShadow: 'none',
        color: colors.defaultTextLight,
        fontSize: '14px',
        lineHeight: '16px',
        transition: 'border .3s, color .3s',

        [MQ.m]: {
            paddingTop: '8px',
            paddingBottom: '8px',
        },

        ':focus': {
            outline: 'none',
            color: colors.defaultText,
            borderColor: colors.primary,
        },

        ...placeholderPseudo({ color: colors.primaryTextMuted }),
    },

    textarea: {
        appearance: 'textarea',
        display: 'block',
        margin: '0',
        minHeight: '200px',
        // overflowX: 'scroll',
        resize: 'vertical',
        fontFamily: 'Consolas, \'Liberation Mono\', Menlo, Courier, monospace',
        fontSize: '13px',
        whiteSpace: 'pre', // 'pre' or 'nowrap'
    },

    autoHeight: (min = null, max = null) => ({
        height: 'auto',
        ...(min && { minHeight: `${min}px` }),
        ...(max && { maxHeight: `${max}px` }),
    }),

    tag: {
        display: 'inline-block',
        margin: '2px 5px 2px 0',
        padding: '3px 5px 3px 8px',
        border: `1px solid ${colors.primary}`,
        borderRadius: '3px',
        backgroundColor: colors.primaryText,
        color: colors.defaultTextLight,
        fontSize: '11px',
        lineHeight: '1',
        verticalAlign: 'middle',
        cursor: 'pointer',

        ':hover': {
            backgroundColor: colors.primary,
            color: colors.primaryText,
            cursor: 'pointer',
        },

        '::after': {
            content: '"\\d7"',
            display: 'inline-block',
            width: '12px',
            height: '12px',
            marginLeft: '5px',
            fontSize: '10px',
            lineHeight: '1',
            verticalAlign: 'text-bottom',
            textAlign: 'center',
        },

        ':hover::after': {
            borderRadius: '50%',
            backgroundColor: colors.primaryText,
            color: colors.primary,
        },
    },

    tagSelected: {
        backgroundColor: colors.primary,
        color: colors.primaryText,
        cursor: 'pointer',

        '::after': {
            borderRadius: '50%',
            backgroundColor: colors.primaryText,
            color: colors.primary,
        },
    },

    colorBox: (background) => ({
        width: '20px',
        height: '20px',
        backgroundColor: background,
    }),
};

export const Container = styled('div', ({ $noMargin = false }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '10px',
    ...($noMargin && { marginBottom: '0' }),
}));

export const Box = styled('div', props => ({
    boxSizing: 'border-box',
    margin: '0',
    flex: '0 0 100%',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${colors.primaryTextMuted}`,
    borderRadius: '2px',

    [MQ.s]: {
        flex: '1 1 auto',
        ...(props.$auto && { flex: '0 0 auto' }),
    },

    ...(props.$padded && { padding: '0 12px' }),
    ...(props.$marginLeft && ({ margin: '0 0 0 10px' })),
}));

export const Label = styled('span', ({ $marginLeft = false, $spaced = true }) => ({
    display: 'inline-block',
    marginRight: '10px',
    marginBottom: '6px',
    color: colors.primaryTextLight,
    fontSize: '11px',
    fontWeight: '800',
    textTransform: 'uppercase',

    ...($marginLeft && { marginLeft: '10px' }),
    ...(!$spaced && { marginBottom: '0' }),
}));

export const Explain = styled('div', ({ $error = false }) => ({
    width: '100%',
    marginTop: '6px',
    fontSize: '11px',
    color: colors.defaultTextMuted,
    ...($error && { color: colors.accent }),
}));

export const Input = styled('input', props => combineStyles(
    Styles.box,
    Styles.input,
    ((props.disabled || props.readOnly) && {
        color: colors.defaultTextLight,
        borderColor: '#ddd',
        background: '#eee',
    }),
    (props.$halfWidth && { minWidth: '50%' }),
    (props.$large && {
        fontSize: '16px',
        lineHeight: '20px',
        [MQ.m]: {
            paddingTop: '12px',
            paddingRight: '16px',
            paddingBottom: '12px',
            paddingLeft: '16px',
        },
    }),
    (props.$leftIcon && {
        paddingLeft: '36px',
        [MQ.m]: {
            paddingLeft: '36px',
        },
    }),
));

export const Textarea = styled('textarea', props => ({
    ...Styles.box,
    ...Styles.input,
    ...Styles.textarea,
    ...(props.wrap && {
        whitespace: 'wrap',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    }),
    ...(props.$autoHeight && Styles.autoHeight(props.minHeight, props.maxHeight)),
    ...(props.$fullWidth && { width: '100%' }),
}));


export const TagsWrapper = styled('div', {
    flex: '1 1 auto',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px 0',
    margin: '-2px 0',
});
export const Tag = styled('span', ({ $selected }) => combineStyles(
    Styles.tag,
    $selected && Styles.tagSelected,
));
export const TagInput = styled('input', {
    flex: '1 1 50px',
    appearance: 'none',
    width: '0',
    border: 'none',
    outline: 'none',
    margin: '0',
    padding: '0',
    fontSize: '12px',
    lineHeight: '14px',
    background: colors.primaryText,
    boxShadow: 'none',
    ...placeholderPseudo({ color: '#ccc' }),
});

export const ColorBox = styled('div', ({ $background }) => ({
    ...Styles.colorBox($background),
    marginLeft: '10px',
}));
