import React from 'react';

const List = ({ className = '', size = '1em' }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 512 512">
        <g>
            <path d="m31.9,125.9h73.8v-73.7h-73.8v73.7h-7.10543e-15zm84.1,20.9h-94.6c-5.8,0-10.4-4.7-10.4-10.4v-94.7c0-5.8 4.7-10.4 10.4-10.4h94.6c5.8,0 10.4,4.7 10.4,10.4v94.6c0.1,5.8-4.6,10.5-10.4,10.5z"/>
            <path d="m490.6,99.5h-324.5c-5.8,0-10.4-4.7-10.4-10.4 0-5.8 4.7-10.4 10.4-10.4h324.5c5.8,0 10.4,4.7 10.4,10.4 0,5.7-4.7,10.4-10.4,10.4z"/>
            <path d="m31.9,292.9h73.8v-73.8h-73.8v73.8h-7.10543e-15zm84.1,20.8h-94.6c-5.8,0-10.4-4.7-10.4-10.4v-94.6c0-5.8 4.7-10.4 10.4-10.4h94.6c5.8,0 10.4,4.7 10.4,10.4v94.6c0.1,5.8-4.6,10.4-10.4,10.4z"/>
            <path d="m490.6,266.4h-324.5c-5.8,0-10.4-4.7-10.4-10.4 0-5.8 4.7-10.4 10.4-10.4h324.5c5.8,0 10.4,4.7 10.4,10.4 0,5.8-4.7,10.4-10.4,10.4z"/>
            <path d="m31.9,459.8h73.8v-73.8h-73.8v73.8h-7.10543e-15zm84.1,20.9h-94.6c-5.8,0-10.4-4.7-10.4-10.4v-94.6c0-5.8 4.7-10.4 10.4-10.4h94.6c5.8,0 10.4,4.7 10.4,10.4v94.6c0.1,5.7-4.6,10.4-10.4,10.4z"/>
            <path d="m490.6,433.4h-324.5c-5.8,0-10.4-4.7-10.4-10.4s4.7-10.4 10.4-10.4h324.5c5.8,0 10.4,4.7 10.4,10.4s-4.7,10.4-10.4,10.4z"/>
        </g>
    </svg>
);

export default List;
