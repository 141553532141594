import React from 'react';

const Layers = ({ className = '', size = '1em' }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 55 55">
        <g>
            <path d="M45.297,21.946l9.656-5.517L27.477,0.825L0,16.429l9.656,5.517L0,27.429l9.656,5.517L0,38.429l27.477,15.698l27.476-15.698
                l-9.656-5.483l9.656-5.517L45.297,21.946z M27.477,3.125l23.435,13.309l-23.435,13.39L4.041,16.434L27.477,3.125z M11.675,23.099
                l15.802,9.028l15.802-9.028l7.633,4.335l-23.435,13.39L4.041,27.434L11.675,23.099z M50.912,38.434l-23.435,13.39L4.041,38.434
                l7.634-4.335l15.802,9.028l15.802-9.028L50.912,38.434z"/>
        </g>
    </svg>
);

export default Layers;
