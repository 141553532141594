import React from 'react';
import { Helmet } from 'react-helmet';

const HTMLHeader = ({ title = null, description = null }) => (
    <Helmet
        key={(typeof window !== 'undefined') ? window.location.href : 'server'}
        titleTemplate="%s - Snipit"
        defaultTitle="Snipit - Code snippets manager for teams and individuals"
    >
        { title && <title>{title}</title> }
        { description && <meta name='description' content={description} /> }
    </Helmet>
);

export default HTMLHeader;
