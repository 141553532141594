import { styled } from 'styletron-react';
import { getTheme } from '../../themes';


const colors = getTheme();

export const CodeWrapper = styled('div', ({ $noMargin = false }) => ({
    boxSizing: 'border-box',
    position: 'relative',
    maxHeight: '100%',
    border: `1px solid ${colors.primaryTextMuted}`,
    borderRadius: '2px',

    ...(!$noMargin && {
        margin: '30px 0',
    }),
}));

export const Footer = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 10px',
    fontSize: '12px',
    color: colors.defaultTextMuted,
    background: '#f4f4f4',
    borderTop: `1px solid ${colors.primaryTextMuted}`,
});

export const FooterLinkContainer = styled('div', {
    flex: '1 1 1px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

export const FooterLink = styled('a', {
    color: colors.primary,
    textDecoration: 'underline',

    ':hover': {
        textDecoration: 'none',
    },
});

export const FooterWatermarkContainer = styled('div', {
    flex: '0 0 auto',
    marginLeft: '20px',
});
