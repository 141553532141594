export default {
    primary: '#009aff',
    primaryLight: '#00baff', // #4CB8FF / #00baff
    primaryDark: '#004d90', // #004D7F / #004d90

    primaryText: '#fff',
    primaryTextMuted: '#cde',
    primaryTextLight: '#89a',
    defaultText: '#202224',
    defaultTextLight: '#44484c',
    defaultTextMuted: '#678',

    accent: '#ff006a',

    success: '#00cc6a',

    danger: '#e22',
    dangerDark: '#c11',
};
