import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { cmGetMode } from '../../../store/actions/cm';
import { getNameOrMime } from '../../../helpers/cm';
import { Textarea } from '../input/InputStyles';
import { CodeWrapper } from './CodeEditorStyles';
import Watermark from './Watermark';
import CopyToClipboardButton from '../copyToClipboardButton';


const getCMInstance = () => {
    if (typeof window.CodeMirror !== 'undefined') {
        return window.CodeMirror;
    }
    return null;
};

class CodeEditor extends Component {
    constructor(props) {
        super(props);

        this.codeBlockRef = null;
        this.CodeMirror = null;
        this.clipboard = null;

        this.state = {
            hasMode: false,
            copied: false,
        };

        this.initCM = this.initCM.bind(this);
        this.onCodeChange = this.onCodeChange.bind(this);
    }

    initCM(props) {
        if (getCMInstance()) {
            if (!this.CodeMirror && props.modes[props.language]) {
                this.CodeMirror = getCMInstance().fromTextArea(this.codeBlockRef, {
                    mode: {
                        name: getNameOrMime(props.language, props.code),
                    },
                    lineWrapping: (this.props.wrap !== null ? this.props.wrap : true),
                    autoCloseBrackets: true,
                    autoCloseTags: true,
                    matchBrackets: true,
                    matchTags: true,
                    showTrailingSpace: true,
                    lineNumbers: props.showLineNumbers || props.editable,
                    readOnly: !props.editable,
                });

                this.CodeMirror.on('change', (editor, change) => this.onCodeChange(editor.getValue()));
            }

            if (!props.modes[ props.language ]) {
                props.loadMode(props.language);
            }

            if (this.CodeMirror) {
                if (
                    (props.language !== this.props.language)
                    || (props.modes[ props.language ] !== this.props.modes[ props.language ])
                ) {
                    this.CodeMirror.setOption('mode', {
                        name: getNameOrMime(props.language, props.code),
                    });
                    this.setState({ copied: false });
                }

                if (props.editable !== this.props.editable) {
                    this.CodeMirror.setOption('readOnly', !props.editable);
                    this.CodeMirror.setOption('lineNumbers', props.showLineNumbers || props.editable);
                    this.CodeMirror.setValue(props.code);
                    this.setState({ copied: false });
                }

                if (props.code !== this.CodeMirror.getValue()) {
                    this.CodeMirror.setValue(props.code);
                    this.setState({ copied: false });
                }
            }
        }
    }

    componentDidMount() {
        this.initCM(this.props);
        // this.initClipboard();
    }

    componentWillReceiveProps(nextProps) {
        this.initCM(nextProps);
    }

    onCodeChange(code) {
        this.props.onCodeChange(code);
    }

    render() {
        return (
            <CodeWrapper $noMargin={this.props.noMargin || this.props.editable}>
                { this.props.showCopyBtn && (
                    <CopyToClipboardButton
                        textToCopy={this.props.code}
                        $right
                    />
                ) }
                <Textarea
                    $ref={el => (this.codeBlockRef = el)}
                    value={this.props.code}
                    readOnly={!this.props.editable}
                    onChange={e => this.onCodeChange(e.target.value)}
                    $fullWidth
                    $autoHeight
                    $minHeight={this.props.collapse ? '0' : '200'}
                />

                { this.props.watermark
                    ? <Watermark
                        title={this.props.title}
                        permalink={this.props.permalink}
                    />
                    : null
                }
            </CodeWrapper>
        );
    }
}

CodeEditor.propTypes = {
    code: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    showLineNumbers: PropTypes.bool,
    showCopyBtn: PropTypes.bool,
    onCodeChange: PropTypes.func,

    // style props
    noMargin: PropTypes.bool,
    collapse: PropTypes.bool,
    wrap: PropTypes.bool,
    permalink: PropTypes.string,
};

CodeEditor.defaultProps = {
    editable: true,
    showLineNumbers: true,
    showCopyBtn: true,
    onCodeChange: () => {},
    // style props
    noMargin: false,
    collapse: false,
    wrap: null,
    title: '',
    permalink: null,
};


export default connect(
    ({ cm }) => ({
        cmLoaded: cm.cmLoaded,
        modes: cm.modes,
    }),
    dispatch => ({
        loadMode: mode => dispatch(cmGetMode(mode)),
    }),
)(CodeEditor);
