import React from 'react';

const Tick = ({ className = '', size = '1em' }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 512 512">
        <path d="M495.343,45.601H221.818c-9.199,0-16.657,7.458-16.657,16.657V91.18H16.657C7.458,91.18,0,98.638,0,107.837v341.906
            c0,9.199,7.458,16.657,16.657,16.657h341.918c9.199,0,16.657-7.458,16.657-16.657V261.25h120.112
            c9.199,0,16.657-7.458,16.657-16.657V62.257C512,53.058,504.542,45.601,495.343,45.601z M341.918,433.086H33.313V124.493h171.847
            v113.21l-40.56,40.56l-13.646-13.647c-9.057-9.059-24.555-4.896-27.867,7.467l-18.612,69.462
            c-3.297,12.295,7.966,23.731,20.4,20.401l69.462-18.612c12.38-3.319,16.515-18.821,7.467-27.868l-13.647-13.647l40.565-40.565
            h113.197V433.086z M478.687,227.936H238.48c0-101.027-0.006-146.919-0.006-149.022h240.212V227.936z"/>

    </svg>
);

export default Tick;
